import { ref, computed } from "vue";
import type { SubscriptionPlan } from "@/api/model";
import { EnrollmentStatusEnum } from "@/api/model";
import type { AdminV1PaymentsSubscriptionPlansSubscriberMembershipsListResult } from "@/api/admin-payments-subscriber-memberships/admin-payments-subscriber-memberships";
import {
  getSubscription,
  getSubscriptionPlanMemberships,
  updateSubscriptionPlan,
} from "../api";

export function useSubscription() {
  const subscription = ref<SubscriptionPlan | null>(null);
  const memberships = ref<
    AdminV1PaymentsSubscriptionPlansSubscriberMembershipsListResult | undefined
  >(undefined);
  const fetchSubscriptionError = ref<string | null>(null);
  const fetchMembershipsError = ref<string | null>(null);
  const updateError = ref<string | null>(null);

  const lastThirtyDaysMembershipCount = computed(() => {
    if (!memberships.value?.results) return 0;
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    return memberships.value.results.filter(
      (membership) => new Date(membership.created_at) >= thirtyDaysAgo
    ).length;
  });

  async function fetchSubscription(id: string) {
    const result = await getSubscription(id);
    if ("error" in result) {
      fetchSubscriptionError.value = result.error;
    } else {
      subscription.value = result;
    }
  }

  async function fetchMemberships(id: string) {
    const result = await getSubscriptionPlanMemberships(id);
    if ("error" in result) {
      fetchMembershipsError.value = result.error;
    } else {
      memberships.value = result;
    }
  }

  async function publishSubscription(id: string) {
    updateError.value = null;
    const result = await updateSubscriptionPlan(id, { type: "publish" });
    if ("error" in result) {
      updateError.value = result.error;
      return false;
    }
    subscription.value = result;
    return true;
  }

  async function archiveSubscription(id: string) {
    updateError.value = null;
    const result = await updateSubscriptionPlan(id, { type: "archive" });
    if ("error" in result) {
      updateError.value = result.error;
      return false;
    }
    subscription.value = result;
    return true;
  }

  async function updateEnrollmentStatus(
    id: string,
    status: EnrollmentStatusEnum
  ) {
    updateError.value = null;
    const result = await updateSubscriptionPlan(id, {
      type: "enrollment",
      status,
    });
    if ("error" in result) {
      updateError.value = result.error;
      return false;
    }
    subscription.value = result;
    return true;
  }

  return {
    subscription,
    memberships,
    fetchSubscription,
    fetchMemberships,
    fetchSubscriptionError,
    fetchMembershipsError,
    updateError,
    lastThirtyDaysMembershipCount,
    publishSubscription,
    archiveSubscription,
    updateEnrollmentStatus,
  };
}
