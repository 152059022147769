import { adminV1PaymentsStatisticsList } from "@/api/admin-payments-paypoint-statistics/admin-payments-paypoint-statistics";
import {
  AdminV1PaymentsStatisticsListFreq,
  AdminV1PaymentsStatisticsListMode,
  type AdminV1PaymentsStatisticsListParams,
  type PaypointStatistics,
} from "@/api/model";
import { computed, ref, type ComputedRef, type Ref } from "vue";
import {
  getFormattedCurrency,
  getFormattedPercentage,
} from "@/shared/utils/helpers";

export function usePaypointStatistics(): {
  statsData: Ref<PaypointStatistics[]>;
  mode: Ref<AdminV1PaymentsStatisticsListMode>;
  freq: AdminV1PaymentsStatisticsListFreq;
  fetchStats: () => Promise<void>;
  totalTransactionVolume: ComputedRef<string>;
  numberOfTransactions: ComputedRef<number>;
  avgTransactionValue: ComputedRef<string>;
  chargebackRate: ComputedRef<string>;
  achReturnRate: ComputedRef<string>;
  failedTransactionVolume: ComputedRef<string>;
} {
  const freq: AdminV1PaymentsStatisticsListFreq =
    AdminV1PaymentsStatisticsListFreq.m;
  const mode: Ref<AdminV1PaymentsStatisticsListMode> = ref(
    AdminV1PaymentsStatisticsListMode.d30
  );
  const statsData = ref<PaypointStatistics[]>([]);

  const statsParams = computed<AdminV1PaymentsStatisticsListParams>(() => ({
    freq: freq,
    mode: mode.value,
  }));

  const fetchStats = async () => {
    try {
      const statsResult = await adminV1PaymentsStatisticsList(
        statsParams.value
      );
      statsData.value = statsResult;
    } catch (error) {
      console.error("Error fetching stats: ", error);
      statsData.value = [];
    }
  };

  const rawTotalTransactionVolume = computed(
    () =>
      statsData.value?.reduce(
        (acc: number, item) => acc + item.in_transactions_volume,
        0
      ) ?? 0
  );

  const totalTransactionVolume = computed(() => {
    return getFormattedCurrency(rawTotalTransactionVolume.value);
  });

  const numberOfTransactions = computed(() => {
    const sum =
      statsData.value?.reduce(
        (acc: number, item) => acc + item.in_transactions,
        0
      ) ?? 0;
    return sum;
  });

  const avgTransactionValue = computed(() => {
    return getFormattedCurrency(
      numberOfTransactions.value === 0
        ? 0
        : rawTotalTransactionVolume.value / numberOfTransactions.value
    );
  });

  const chargebackRate = computed(() => {
    const inCardChargeBacks =
      statsData.value?.reduce(
        (acc: number, item) => acc + item.in_card_charge_backs,
        0
      ) ?? 0;
    return getFormattedPercentage(
      inCardChargeBacks === 0
        ? 0
        : inCardChargeBacks / rawTotalTransactionVolume.value
    );
  });

  const achReturnRate = computed(() => {
    const inAchReturns =
      statsData.value?.reduce(
        (acc: number, item) => acc + item.in_ach_returns,
        0
      ) ?? 0;
    return getFormattedPercentage(
      inAchReturns === 0 ? 0 : inAchReturns / rawTotalTransactionVolume.value
    );
  });

  const failedTransactionVolume = computed(() => {
    const inCardChargeBackVolume =
      statsData.value?.reduce(
        (acc: number, item) => acc + item.in_card_charge_back_volume,
        0
      ) ?? 0;
    const inAchReturnsVolume =
      statsData.value?.reduce(
        (acc: number, item) => acc + item.in_ach_returns_volume,
        0
      ) ?? 0;
    return getFormattedCurrency(inCardChargeBackVolume + inAchReturnsVolume);
  });
  return {
    statsData,
    mode,
    freq,
    fetchStats,
    totalTransactionVolume,
    numberOfTransactions,
    avgTransactionValue,
    chargebackRate,
    achReturnRate,
    failedTransactionVolume,
  };
}
