/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import adminV1PaymentsSubscriberMembershipsScheduledPaymentsRefundCreateMutator from '../../shared/services/axios-client';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  /**
 * Refund a scheduled payment
 * @summary Refund a scheduled payment
 */
export const adminV1PaymentsSubscriberMembershipsScheduledPaymentsRefundCreate = (
    subPk: string,
    id: string,
 options?: SecondParameter<typeof adminV1PaymentsSubscriberMembershipsScheduledPaymentsRefundCreateMutator>,) => {
      return adminV1PaymentsSubscriberMembershipsScheduledPaymentsRefundCreateMutator<void>(
      {url: `/api/admin/v1/payments/subscriber-memberships/${subPk}/scheduled_payments/${id}/refund/`, method: 'POST'
    },
      options);
    }
  export type AdminV1PaymentsSubscriberMembershipsScheduledPaymentsRefundCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSubscriberMembershipsScheduledPaymentsRefundCreate>>>
