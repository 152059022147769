import { ref } from "vue";
import { adminV1PaymentsInvoicesRefundCreate } from "@/api/admin-payments-invoices/admin-payments-invoices";

export function useRefundInvoice() {
  const isLoading = ref(false);
  const errorMessage = ref("");

  const refundInvoice = async (invoiceId: string) => {
    isLoading.value = true;
    errorMessage.value = "";

    try {
      await adminV1PaymentsInvoicesRefundCreate(invoiceId);
    } catch (error: any) {
      errorMessage.value = error?.message || "Failed to refund invoice";
      console.error(error);
    }

    isLoading.value = false;
  };

  return {
    isLoading,
    errorMessage,
    refundInvoice,
  };
}
