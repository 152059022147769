import { ref } from "vue";
import { adminV1PaymentsSubscriberMembershipsScheduledPaymentsRefundCreate } from "@/api/admin-payments-scheduled-payments/admin-payments-scheduled-payments";

export function useRefundScheduledPayment() {
  const isLoading = ref(false);
  const errorMessage = ref("");

  const refundScheduledPayment = async (
    subscriberMembershipId: string,
    scheduledPaymentId: string
  ) => {
    isLoading.value = true;
    errorMessage.value = "";

    try {
      await adminV1PaymentsSubscriberMembershipsScheduledPaymentsRefundCreate(
        subscriberMembershipId,
        scheduledPaymentId
      );
    } catch (error: any) {
      errorMessage.value =
        error?.message || "Failed to refund scheduled payment";
      console.error(error);
    }

    isLoading.value = false;
  };

  return {
    isLoading,
    errorMessage,
    refundScheduledPayment,
  };
}
