<template>
  <BaseLayout
    title="View Membership"
    :breadcrumb="[
      {
        label: subscriberMembership?.subscription_plan.title,
        to: {
          name: ROUTE_NAME.PAYMENT_MEMBERSHIPS_VIEW,
          params: {
            id: subscriberMembership?.subscription_plan.id,
          },
        },
      },
      {
        label: subscriberMembership?.associated_contact.name,
      },
    ]"
  >
    <div class="flex justify-end mb-4 gap-2">
      <ActionsMenu
        :menu-items="menuItems"
        :dangerous-action="cancelSubscriberMembershipAction"
      />
    </div>
    <div>
      <div v-if="subscriberMembership">
        <SubscriberMembership
          :subscriber-membership="subscriberMembership"
          :scheduled-payments="scheduledPayments"
        />
        <SubscriptionTransactions
          :subscriber-membership="subscriberMembership"
          :scheduled-payments="scheduledPayments"
        />
      </div>
      <div v-else class="text-center p-4 text-gray-500">
        Membership member not found.
      </div>
    </div>
    <ConfirmDialog group="subscriber-membership">
      <template #container="{ message, acceptCallback, rejectCallback }">
        <div class="flex flex-col p-8 bg-surface-0 dark:bg-surface-900 rounded">
          <h3 class="text-xl font-bold mb-4">{{ message.header }}</h3>
          <p class="whitespace-pre-line mb-6">{{ message.message }}</p>
          <div class="flex flex-col gap-2">
            <label class="text-sm">Type "delete" to confirm:</label>
            <InputText
              v-model="deleteConfirmText"
              placeholder="delete"
              class="w-full"
            />
          </div>
          <div class="flex justify-end gap-4 mt-6">
            <button
              type="button"
              class="text-gray-600 hover:text-gray-800"
              @click="rejectCallback"
            >
              No
            </button>
            <Button
              :disabled="deleteConfirmText !== 'delete'"
              severity="danger"
              @click="acceptCallback"
            >
              Yes, Cancel
            </Button>
          </div>
        </div>
      </template>
    </ConfirmDialog>
  </BaseLayout>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import ActionsMenu from "@/shared/components/ActionsMenu.vue";
import SubscriberMembership from "@/modules/offerings/components/SubscriberMembership.vue";
import SubscriptionTransactions from "@/modules/offerings/components/SubscriptionTransactions.vue";
import { useSubscriberMembership } from "@/modules/offerings/composables/useSubscriberMembership";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useScheduledPayments } from "@/modules/offerings/composables/useScheduledPayments";
import { deleteSubscriberMembership } from "@/modules/offerings/api";
import { useToastStore } from "@/shared/stores/toastStore";

const route = useRoute();
const router = useRouter();
const toast = useToast();
const { subscriberMembership, fetchSubscriberMembership } =
  useSubscriberMembership();

const confirm = useConfirm();
const deleteConfirmText = ref("");

const { scheduledPayments, fetchScheduledPayments } = useScheduledPayments();

const toastStore = useToastStore();

const CANCEL_SUBSCRIBER_MEMBERSHIP_MESSAGES = {
  group: "subscriber-membership",
  title: "Cancel Subscriber Membership",
  header: "Cancel Subscriber Membership",
  message:
    "Are you sure you want to cancel this subscriber membership?\n\nThis is a permanent action and cannot be undone.",
};

const cancelSubscriberMembershipAction = computed(() => ({
  label: "Cancel",
  icon: "pi pi-trash",
  dangerous: true as const,
  command: () => {
    deleteConfirmText.value = "";
    confirm.require({
      ...CANCEL_SUBSCRIBER_MEMBERSHIP_MESSAGES,
      accept: async () => {
        try {
          if (!subscriberMembership.value?.id) return;

          const result = await deleteSubscriberMembership(
            subscriberMembership.value.id
          );

          if ("error" in result) {
            throw new Error(result.error);
          }

          toastStore.setPendingToast({
            severity: "success",
            detail: "Membership cancelled successfully",
          });

          router.push({
            name: ROUTE_NAME.PAYMENT_MEMBERSHIPS_VIEW,
            params: {
              id: subscriberMembership.value.subscription_plan.id,
            },
          });
        } catch (error) {
          console.error("Failed to cancel membership:", error);
          toast.add({
            severity: "error",
            detail: "Failed to cancel membership",
          });
        }
      },
    });
  },
}));

const menuItems = [
  {
    label: "Email",
    icon: "pi pi-envelope",
    command: () => {
      const email = subscriberMembership.value?.associated_contact?.email || "";
      window.location.href = `mailto:${email}`;
    },
  },
];

onMounted(async () => {
  await fetchSubscriberMembership(route.params.id as string);
  if (subscriberMembership.value) {
    await fetchScheduledPayments(subscriberMembership.value.id);
  }
});
</script>
