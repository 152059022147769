<template>
  <div class="flex justify-end">
    <Button
      type="button"
      label="Actions"
      icon="pi pi-chevron-down"
      icon-pos="right"
      class="!bg-gray-500 !border-gray-600 hover:!bg-gray-600 focus:!border-gray-400 active:!border-gray-400"
      @click="menu.toggle($event)"
    />
    <Menu ref="menu" :model="computedMenuItems" :popup="true">
      <template #item="{ item }">
        <a
          class="flex items-center p-2 cursor-pointer hover:!bg-gray-100"
          :class="{
            'text-red-500': item.dangerous,
            'opacity-50 cursor-not-allowed': item.disabled,
          }"
          @click="item.disabled ? undefined : item.command"
        >
          <i :class="[item.icon, 'mr-2']"></i>
          <span>{{ item.label }}</span>
        </a>
      </template>
    </Menu>
  </div>
</template>

<script setup lang="ts">
import Button from "primevue/button";
import Menu from "primevue/menu";
import { ref, computed } from "vue";

interface MenuItem {
  label: string;
  icon: string;
  command: () => void;
  disabled?: boolean;
}

interface DangerousAction extends MenuItem {
  dangerous: true;
}

const props = defineProps<{
  menuItems: MenuItem[];
  dangerousAction?: DangerousAction;
}>();

const menu = ref();

const computedMenuItems = computed(() => {
  if (props.dangerousAction) {
    return [...props.menuItems, props.dangerousAction];
  }
  return props.menuItems;
});
</script>
