<template>
  <div class="flex max-md:flex-col">
    <AppCard class="w-3/4 max-md:w-full">
      <div class="flex items-center max-md:flex-col">
        <h1 class="text-xl font-bold mb-4">Transaction Overview</h1>
        <Select
          v-model="mode"
          :options="PAYPOINT_STATISTICS_MODE_OPTIONS"
          option-label="label"
          option-value="value"
          class="w-full sm:w-48 mb-4 sm:ml-4"
          placeholder="Select a date filter"
        />
      </div>
      <div class="grid grid-cols-3 gap-4 mb-4 max-md:flex-col max-md:flex">
        <div
          class="p-4 bg-white rounded-lg shadow-md border-surface-200 border-[1px]"
        >
          <div class="text-surface-500 dark:text-surface-300 text-sm">
            Total Transaction Volume
          </div>
          <div class="font-semibold">{{ totalTransactionVolume }}</div>
        </div>
        <div class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]">
          <div class="text-surface-500 dark:text-surface-300 text-sm">
            Number of Transactions
          </div>
          <div class="font-semibold">{{ numberOfTransactions }}</div>
        </div>
        <div class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]">
          <div class="text-surface-500 dark:text-surface-300 text-sm">
            Avg. Transaction Value
          </div>
          <div class="font-semibold">{{ avgTransactionValue }}</div>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-4 mb-4 max-md:flex-col max-md:flex">
        <div class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]">
          <div class="text-surface-500 dark:text-surface-300 text-sm">
            Chargeback Rate
          </div>
          <div class="font-semibold">{{ chargebackRate }}</div>
        </div>
        <div class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]">
          <div class="text-surface-500 dark:text-surface-300 text-sm">
            ACH Return Rate
          </div>
          <div class="font-semibold">{{ achReturnRate }}</div>
        </div>
        <div class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]">
          <div class="text-surface-500 dark:text-surface-300 text-sm">
            Failed Transaction Volume
          </div>
          <div class="font-semibold">{{ failedTransactionVolume }}</div>
        </div>
      </div>
    </AppCard>
    <AppCard class="w-1/4 max-md:w-full md:ml-4 max-md:mt-4">
      <h1 class="text-xl font-bold mb-4">Past Due</h1>
      <div class="grid grid-cols-1 gap-4 mb-4">
        <div class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]">
          <div class="text-surface-500 dark:text-surface-300 text-sm">
            Past Due Revenue
          </div>
          <div class="font-semibold">{{ totalPastDueAmount }}</div>
        </div>
        <div class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]">
          <div class="text-surface-500 dark:text-surface-300 text-sm">
            Past Due Invoices
          </div>
          <div class="font-semibold">{{ totalRecords }}</div>
        </div>
      </div>
    </AppCard>
  </div>
</template>

<script setup lang="ts">
import AppCard from "@/shared/components/AppCard.vue";

import { useInvoices } from "@/modules/payments/composables/useInvoices";
import { usePaypointStatistics } from "@/modules/payments/composables/usePaypointStatistics";
import Select from "primevue/select";
import { type Invoice } from "@/api/model";
import {
  PAYPOINT_STATISTICS_MODE_OPTIONS,
  PAST_DUE_INVOICE_FILTER,
} from "../constants";
import { computed, onMounted, watch } from "vue";
import { getFormattedCurrency } from "@/shared/utils/helpers";

const {
  fetchStats,
  mode,
  totalTransactionVolume,
  numberOfTransactions,
  avgTransactionValue,
  chargebackRate,
  achReturnRate,
  failedTransactionVolume,
} = usePaypointStatistics();

const { fetchInvoices, filter, totalRecords, pageSize, paginatedInvoices } =
  useInvoices();

filter.value = PAST_DUE_INVOICE_FILTER;
pageSize.value = 10000;

const totalPastDueAmount = computed(() => {
  const sum =
    paginatedInvoices.value?.results?.reduce(
      (acc: number, invoice: Invoice) => acc + invoice.invoice_amount,
      0
    ) ?? 0;
  return getFormattedCurrency(sum);
});

watch(mode, async () => {
  await fetchStats();
});

onMounted(async () => {
  await fetchStats();
  await fetchInvoices();
});
</script>
