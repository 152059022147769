<script setup lang="ts">
import type { Invoice } from "@/api/model";
import { getFormattedCurrency } from "@/shared/utils/helpers";

const props = defineProps<{
  invoice: Invoice;
}>();

const { invoice } = props;

import Checkbox from "primevue/checkbox";
import { computed } from "vue";

const {
  installments_term_length: installmentsTermLength,
  invoice_amount: invoiceTotal,
  installments_payment_day: installmentsPaymentDay,
} = invoice;

const hasPaymentTerms = computed(() => {
  return (
    invoice.installments_term_length &&
    invoice.installments_payment_day &&
    invoice.invoice_amount
  );
});

const paymentPerTerm = computed(() => {
  if (!installmentsTermLength || !invoiceTotal) {
    return 0;
  }

  return `${getFormattedCurrency(invoiceTotal / installmentsTermLength)}/month`;
});
</script>

<template>
  <div>
    <h2 class="text-xl font-bold mb-4">Installments Summary</h2>
  </div>
  <div v-if="invoice.installments_enabled" class="mt-4">
    <div class="flex flex-col sm:flex-row sm:space-x-2">
      <div class="flex-1 mb-2 sm:mb-0">
        <label class="block text-sm font-medium text-gray-700 mb-1">
          Auto-payment day:
        </label>
        <p class="text-sm text-gray-700 font-light">
          {{ invoice.installments_payment_day }} day of each month
        </p>
      </div>
      <div class="flex-1">
        <label
          for="installmentsTermLength"
          class="block text-sm font-medium text-gray-700 mb-1"
        >
          Term
        </label>
        <p class="text-sm text-gray-700 font-light">
          {{ invoice.installments_term_length }} Months
        </p>
      </div>
    </div>

    <div class="mt-4">
      <label class="block text-sm font-medium text-gray-700 mb-1">
        Installment Amount
      </label>
      <div v-if="hasPaymentTerms">
        <p class="text-sm text-gray-500">{{ paymentPerTerm }}</p>
      </div>
    </div>

    <div class="mt-4" v-if="invoice.installments_schedule?.length">
      <label class="block text-sm font-medium text-gray-700 mb-1">
        Payment Schedule
      </label>
      <div class="w-1/2 text-sm border border-gray-200 rounded">
        <div
          v-for="(payment, index) in invoice.installments_schedule"
          :key="index"
          class="flex justify-between px-3 py-2 gap-2 border-b border-gray-200 last:border-b-0"
        >
          <div class="font-thin">
            Installment {{ index + 1 }}
            <span
              v-if="new Date(payment.due_date) < new Date()"
              class="text-green-600"
            >
              (Paid)
            </span>
          </div>
          <div class="font-thin">
            {{ payment.due_date }} ({{ getFormattedCurrency(payment.amount) }})
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
