<template>
  <AppCard>
    <div>
      <h1 class="text-2xl font-bold">
        {{ subscriberMembership.associated_contact.name }}
      </h1>
      <div class="flex flex-row gap-2 border border-gray-200 p-4 mt-4">
        <div>
          <label class="text-md font-semibold text-gray-500">Player</label>
          <p class="text-md">
            {{ subscriberMembership.subscription_participant.name }}
          </p>
        </div>
        <div class="ml-8">
          <label class="text-md font-semibold text-gray-500">Email</label>
          <p class="">
            {{ subscriberMembership.associated_contact.email }}
          </p>
        </div>
        <div class="ml-8">
          <label class="text-md font-semibold text-gray-500"
            >Date Subscribed</label
          >
          <p class="">
            {{ getFormattedDate(subscriberMembership.created_at) }}
          </p>
        </div>
        <div class="ml-8">
          <label class="text-md font-semibold text-gray-500"
            >Total Collected</label
          >
          <p class="">
            {{ getFormattedCurrency(totalCollected) }}
          </p>
        </div>
        <div class="ml-8">
          <label class="text-md font-semibold text-gray-500">Status</label>
          <p :class="isActive ? 'text-green-600' : 'text-red-600'">
            {{ isActive ? "Active" : "Cancelled" }}
          </p>
        </div>
      </div>
    </div>
  </AppCard>
</template>

<script setup lang="ts">
import type { SubscriberMembership, ScheduledPayment } from "@/api/model";
import AppCard from "@/shared/components/AppCard.vue";
import { getFormattedCurrency } from "@/shared/utils/helpers";
import { computed } from "vue";

const props = defineProps<{
  subscriberMembership: SubscriberMembership;
  scheduledPayments?: ScheduledPayment[];
}>();

const totalCollected = computed(() => {
  return (
    props.scheduledPayments?.reduce(
      (sum, payment) => sum + payment.amount,
      0
    ) ?? 0
  );
});

const isActive = computed(() => {
  return props.subscriberMembership.cancelled_at === null;
});

const getFormattedDate = (date: string | null) => {
  if (!date) return "";
  return new Date(date).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};
</script>
