<template>
  <BaseLayout title="Memberships">
    <SubscriptionsTable />
  </BaseLayout>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import SubscriptionsTable from "../components/SubscriptionsTable.vue";
import { useToastStore } from "@/shared/stores/toastStore";
import { useToast } from "primevue/usetoast";

const toastStore = useToastStore();
const toast = useToast();

onMounted(() => {
  const pendingToast = toastStore.getPendingToast();
  if (pendingToast) {
    toast.add(pendingToast);
  }
});
</script>
