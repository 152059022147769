import { type RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import ViewSubscriptions from "./views/ViewSubscriptions.vue";
import CreateSubscription from "./views/CreateSubscription.vue";
import ViewSubscription from "./views/ViewSubscription.vue";
import ViewSubscriberMembership from "./views/ViewSubscriberMembership.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/payments/memberships",
    name: ROUTE_NAME.PAYMENT_MEMBERSHIPS,
    component: ViewSubscriptions,
  },
  {
    path: "/payments/memberships/create",
    name: ROUTE_NAME.PAYMENT_MEMBERSHIPS_CREATE,
    component: CreateSubscription,
  },
  {
    path: "/payments/memberships/:id",
    name: ROUTE_NAME.PAYMENT_MEMBERSHIPS_VIEW,
    component: ViewSubscription,
  },
  {
    path: "/payments/memberships/membership/:id",
    name: ROUTE_NAME.PAYMENT_MEMBERSHIPS_MEMBER_VIEW,
    component: ViewSubscriberMembership,
  },
];
