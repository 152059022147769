import { ref } from "vue";
import { type ToastMessageOptions } from "primevue/toast";

const pendingToast = ref<ToastMessageOptions | null>(null);

export const useToastStore = () => {
  const setPendingToast = (message: ToastMessageOptions) => {
    pendingToast.value = message;
  };

  const getPendingToast = () => {
    const message = pendingToast.value;

    pendingToast.value = null;
    return message;
  };

  return {
    setPendingToast,
    getPendingToast,
  };
};
