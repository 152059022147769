import { capitalize, cloneDeep } from "lodash";
import type { ErrorObject } from "@vuelidate/core";
import type FeedFormData from "@/shared/datamodels/feedFormData";
import { AssetType } from "@/shared/datamodels/asset";
import type { AdminAsset } from "@/api/model";

interface IOption {
  value: string;
  title: string;
}
export const convertObjectOptionToInt = (obj: IOption | string | number) => {
  // convert vuetify dropdown object to int
  let option = obj;
  if (typeof option === "object") {
    option = option.value;
  }
  return parseInt(option.toString());
};

export const feedFormToPostData = (form: FeedFormData) => {
  const data = cloneDeep(form);
  data.training_collection_id = convertObjectOptionToInt(
    data?.training_collection_id
  );
  for (let i = 0; i < (data.attachments ?? []).length; i++) {
    if (data.attachments[i]?.id) {
      delete data.attachments[i]["id"];
    }
  }
  return data;
};

export const enumToSelectOptions = (
  enumValue: any,
  returnObject: boolean = true
) => {
  const optionsObject: { name: string; code: string }[] = [];
  const arr: string[] = [];
  let key: keyof typeof enumValue;
  for (key in enumValue) {
    const value = enumValue[key];
    optionsObject.push({
      name: capitalize(value),
      code: value,
    });
    arr.push(value);
  }
  return returnObject ? optionsObject : arr;
};

export const v$ErrorMessage = (
  errors: ErrorObject[]
): string | string[] | undefined => {
  if (!errors) return undefined;

  return errors.length > 0 ? errors[0].$message.toString() : undefined;
};

export const getNameInitials = (fullName: string) => {
  try {
    const n = fullName.split(" ") ?? "";
    return `${n[0].substring(0, 1)}${n[1].substring(0, 1)}`.toUpperCase();
  } catch (e) {
    return "";
  }
};

export const getAssetThumbnail = (asset: AdminAsset, size: number = 300) => {
  if (asset?.type === AssetType.image && asset?.file?.thumbnail_url_tpl) {
    return getThumbnailUrl(asset.file.thumbnail_url_tpl, size);
  }
  if (
    asset?.type === AssetType.video &&
    asset?.video?.cover?.thumbnail_url_tpl
  ) {
    return getThumbnailUrl(asset.video.cover.thumbnail_url_tpl, size);
  }
  if (asset?.file?.thumbnail_url_tpl) {
    return getThumbnailUrl(asset.file.thumbnail_url_tpl, size);
  }
  return undefined;
};

export const getThumbnailUrl = (url: string, size: number = 300) => {
  const s = size.toString();
  return (url || "").replace("{width}", s).replace("{height}", s);
};

export const getFormattedCurrency = (value: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export const getFormattedPercentage = (value: number) => {
  return Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value);
};
