import { ref } from "vue";
import { getSubscriberMembership } from "@/modules/offerings/api";
import type { SubscriberMembership } from "@/api/model/subscriberMembership";

export function useSubscriberMembership() {
  const subscriberMembership = ref<SubscriberMembership | null>(null);
  const fetchSubscriberMembershipError = ref<string | null>(null);

  async function fetchSubscriberMembership(id: string) {
    const result = await getSubscriberMembership(id);
    if ("error" in result) {
      fetchSubscriberMembershipError.value = String(result.error);
    } else {
      subscriberMembership.value = result;
    }
  }

  return {
    subscriberMembership,
    fetchSubscriberMembershipError,
    fetchSubscriberMembership,
  };
}
