import { initialiseModule as initialiseModuleAuth } from "./auth";
import { initialiseModule as initialiseModuleFeed } from "./feed";
import { initialiseModule as initialiseModuleTrainingCollection } from "./trainingCollections";
import { initialiseModule as initialiseModuleWorkout } from "./workouts";
import { initialiseModule as initialiseModuleUsers } from "./users";
import { initialiseModule as initialiseModuleSettings } from "./settings";
import { initialiseModule as initialiseModuleOrganizations } from "./organizations";
import { initialiseModule as initialiseModuleTeams } from "./teams";
import { initialiseModule as initialiseModuleProfile } from "./profile";
import { initialiseModule as initialiseModuleChats } from "./chats";
import { initialiseModule as initialiseModulePayments } from "./payments";
import { initialiseModule as initialiseModuleStats } from "./stats";
import { initialiseModule as initialiseModuleOrganizationPaymentSettings } from "./organizationPaymentSettings";
import { initialiseModule as initialiseModuleSubscriptions } from "./offerings";

import type { ModuleOptions } from "@/types";

export const initialiseModules: (options: ModuleOptions) => void = (opts) => {
  initialiseModuleAuth(opts);
  initialiseModuleFeed(opts);
  initialiseModuleTrainingCollection(opts);
  initialiseModuleWorkout(opts);
  initialiseModuleUsers(opts);
  initialiseModuleSettings(opts);
  initialiseModuleOrganizations(opts);
  initialiseModuleTeams(opts);
  initialiseModuleProfile(opts);
  initialiseModuleChats(opts);
  initialiseModulePayments(opts);
  initialiseModuleStats(opts);
  initialiseModuleOrganizationPaymentSettings(opts);
  initialiseModuleSubscriptions(opts);
};
