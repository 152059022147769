import { ref } from "vue";
import { getScheduledPayments } from "../api";
import type { ScheduledPayment } from "@/api/model/scheduledPayment";

export function useScheduledPayments() {
  const scheduledPayments = ref<ScheduledPayment[]>([]);
  const fetchScheduledPaymentsError = ref<string | null>(null);

  async function fetchScheduledPayments(id: string) {
    const result = await getScheduledPayments(id);
    if ("error" in result) {
      fetchScheduledPaymentsError.value = String(result.error);
    } else {
      scheduledPayments.value = result;
    }
  }

  return {
    scheduledPayments,
    fetchScheduledPayments,
    fetchScheduledPaymentsError,
  };
}
