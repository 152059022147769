<template>
  <AppCard>
    <div class="space-y-4">
      <h1 class="text-2xl font-bold inline-block">{{ subscription?.title }}</h1>
      <span class="text-lg font-semibold text-gray-500 ml-4"
        >{{ getFormattedCurrency(subscription?.amount) }}
        {{ getFrequencyDisplayValue(subscription?.payment_frequency) }}</span
      >
    </div>
    <div class="mt-4 space-y-4">
      <p>
        <template v-if="subscription?.description">
          <span>{{
            isExpanded ? subscription.description : truncatedDescription
          }}</span>
          <button
            v-if="shouldShowReadMore"
            class="text-primary-600 hover:text-primary-700 block mt-2 text-sm font-medium"
            @click="isExpanded = !isExpanded"
          >
            {{ isExpanded ? "See less" : "See more" }}
          </button>
        </template>
      </p>
    </div>
    <div
      v-if="subscription?.subscription_documents?.length ?? 0 > 0"
      class="mt-10 space-y-4"
    >
      <p
        v-if="subscription?.documents_require_acceptance"
        class="text-md text-gray-500"
      >
        Members are required to review and agree to the following document(s).
      </p>
      <p v-else class="text-md text-gray-500">Membership document(s)</p>
      <ul class="flex flex-row gap-2">
        <li
          v-for="document in subscription?.subscription_documents"
          :key="document.id"
        >
          <SubscriptionDocument :document="document" />
        </li>
      </ul>
    </div>
  </AppCard>
  <AppCard class="mt-4">
    <div class="grid grid-cols-3 gap-4 mb-4 max-md:flex-col max-md:flex">
      <div class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]">
        <div class="text-surface-500 dark:text-surface-300 text-sm">
          Members
        </div>
        <div class="font-semibold">
          {{ subscription?.subscriber_membership_count ?? 0 }}
        </div>
        <div
          class="text-xs text-surface-400 font-normal mt-1 bg-gray-50 rounded p-1"
        >
          +{{ lastThirtyDaysMembershipCount }} in last 30 days
        </div>
      </div>
      <div class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]">
        <div class="text-surface-500 dark:text-surface-300 text-sm">
          Recurring Revenue
        </div>
        <div class="font-semibold">{{ formattedRecurringRevenue }}</div>
        <div
          class="text-xs text-surface-400 font-normal mt-1 bg-gray-50 rounded p-1"
        >
          {{ getFrequencyDisplayValue(subscription?.payment_frequency) }}
        </div>
      </div>
      <div class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]">
        <div class="text-surface-500 dark:text-surface-300 text-sm">
          Enrollment
        </div>
        <div class="font-semibold">
          <span
            :class="
              subscription?.enrollment_status === EnrollmentStatusEnum.open
                ? 'text-green-600'
                : 'text-red-600'
            "
          >
            {{ capitalize(subscription?.enrollment_status ?? "") }}
          </span>
          <div
            v-if="subscription?.latest_enrollment_status_update_at"
            class="text-xs text-surface-400 font-normal mt-1 bg-gray-50 rounded p-1"
          >
            Since {{ formattedEnrollmentDate }}
          </div>
        </div>
      </div>
    </div>
  </AppCard>

  <AppCard class="mt-4">
    <div class="flex items-center gap-4 mb-4">
      <h2 class="text-xl font-bold m-0">Members</h2>
      <div class="relative">
        <InputText
          v-model="searchQuery"
          placeholder="Search members..."
          class="w-full sm:w-auto pr-8"
        />
        <i
          class="pi pi-search absolute right-2 top-1/2 -translate-y-1/2 text-gray-500"
        />
      </div>
    </div>
    <DataTable
      :value="filteredMemberships"
      :rows="10"
      :sortable="true"
      striped-rows
      row-hover
      selection-mode="single"
      @row-click="handleRowClick"
    >
      <Column field="associated_contact.name" header="Name" sortable />
      <Column field="associated_contact.email" header="Email" sortable />
      <Column field="start_at" header="Date Subscribed" sortable>
        <template #body="{ data }">
          {{ formatDate(data.created_at) }}
        </template>
      </Column>
      <Column field="cancelled_at" header="Status">
        <template #body="{ data }">
          <span
            :class="
              data.cancelled_at === null ? 'text-green-600' : 'text-red-600'
            "
          >
            {{ data.cancelled_at === null ? "Active" : "Cancelled" }}
          </span>
        </template>
      </Column>
    </DataTable>
    <div
      v-if="filteredMemberships?.length === 0"
      class="flex items-center justify-center mt-4 py-8"
    >
      <p class="text-gray-500">Members will appear here</p>
    </div>
  </AppCard>
</template>

<script setup lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import type { SubscriptionPlan } from "@/api/model";
import type { AdminV1PaymentsSubscriptionPlansSubscriberMembershipsListResult } from "@/api/admin-payments-subscriber-memberships/admin-payments-subscriber-memberships";
import { getFormattedCurrency } from "@/shared/utils/helpers";
import SubscriptionDocument from "./SubscriptionDocument.vue";
import { computed, ref } from "vue";
import { EnrollmentStatusEnum } from "@/api/model";
import { capitalize } from "lodash";
import InputText from "primevue/inputtext";
import { getFrequencyDisplayValue } from "../helpers";
import { useRouter } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";

const props = defineProps<{
  subscription: SubscriptionPlan;
  memberships?: AdminV1PaymentsSubscriptionPlansSubscriberMembershipsListResult;
  lastThirtyDaysMembershipCount: number;
}>();

const isExpanded = ref(false);
const MAX_LENGTH = 250;

const truncatedDescription = computed(() => {
  if (!props.subscription?.description) return "";
  return props.subscription.description.length > MAX_LENGTH
    ? props.subscription.description.slice(0, MAX_LENGTH) + "..."
    : props.subscription.description;
});

const shouldShowReadMore = computed(
  () =>
    props.subscription?.description &&
    props.subscription.description.length > MAX_LENGTH
);

const formattedRecurringRevenue = computed(() => {
  return getFormattedCurrency(props.subscription?.recurring_revenue ?? 0);
});

const formattedEnrollmentDate = computed(() => {
  return props.subscription?.latest_enrollment_status_update_at
    ? formatDate(props.subscription.latest_enrollment_status_update_at)
    : "";
});

const formatDate = (date: string): string => {
  return new Date(date).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const searchQuery = ref("");

const filteredMemberships = computed(() => {
  if (!searchQuery.value || !props.memberships?.results) {
    return props.memberships?.results;
  }

  const query = searchQuery.value.toLowerCase();
  return props.memberships.results.filter(
    (member) =>
      member.associated_contact.name.toLowerCase().includes(query) ||
      member.associated_contact.email.toLowerCase().includes(query)
  );
});

const router = useRouter();

const handleRowClick = (event: { data: any }) => {
  router.push({
    name: ROUTE_NAME.PAYMENT_MEMBERSHIPS_MEMBER_VIEW,
    params: { id: event.data.id },
  });
};
</script>
