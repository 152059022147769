import { ref } from "vue";
import { adminV1PaymentsInvoicesResendEmailCreate } from "@/api/admin-payments-invoices/admin-payments-invoices";

export function useResendPortalEmail() {
  const isLoading = ref(false);
  const errorMessage = ref("");

  const resendEmail = async (invoiceId: string) => {
    isLoading.value = true;
    errorMessage.value = "";

    try {
      await adminV1PaymentsInvoicesResendEmailCreate(invoiceId);
    } catch (error: any) {
      errorMessage.value = error?.message || "Failed to resend portal email";
      console.error(error);
    }

    isLoading.value = false;
  };

  return {
    isLoading,
    errorMessage,
    resendEmail,
  };
}
