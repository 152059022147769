<script setup lang="ts">
import AppCard from "@/shared/components/AppCard.vue";
import Checkbox from "primevue/checkbox";
import Select from "primevue/select";
import InputNumber from "primevue/inputnumber";
import InputGroupAddon from "primevue/inputgroupaddon";

import { v$ErrorMessage } from "@/shared/utils/helpers";
import { computed, toRefs, watch } from "vue";
import { getFormattedCurrency } from "@/shared/utils/helpers";

const installmentsEnabled = defineModel<boolean | null>("installmentsEnabled", {
  required: true,
});

const installmentsTermLength = defineModel<number | null>(
  "installmentsTermLength",
  {
    required: true,
  }
);

const installmentsPaymentDay = defineModel<number | null>(
  "installmentsPaymentDay",
  {
    required: true,
  }
);

const paymentDays = [
  {
    label: "1st",
    value: 1,
  },
  {
    label: "15th",
    value: 15,
  },
];

const props = defineProps({
  invoiceTotal: {
    type: Number,
    required: true,
  },
  fieldValidations: {
    type: Object,
    required: true,
  },
});

const { invoiceTotal } = toRefs(props);

const paymentPerTerm = computed(() => {
  if (!installmentsTermLength.value || !invoiceTotal.value) {
    return 0;
  }

  return `${getFormattedCurrency(invoiceTotal.value / installmentsTermLength.value)}/month`;
});

const hasPaymentTerms = computed(() => {
  return (
    installmentsTermLength.value &&
    installmentsPaymentDay.value &&
    invoiceTotal.value
  );
});

watch(installmentsEnabled, (value) => {
  if (!value) {
    installmentsTermLength.value = null;
    installmentsPaymentDay.value = null;
  }
});
</script>

<template>
  <AppCard class="p-4 mb-3">
    <div class="flex items-start mb-2">
      <Checkbox
        v-model="installmentsEnabled"
        :binary="true"
        class="mr-2 mt-1"
      />
      <div>
        <label for="installmentsEnabled" class="font-medium text-gray-700">
          Allow recipient(s) to choose monthly installments
        </label>
        <p class="text-sm text-gray-500">
          Automatically billed according to your preferred schedule
        </p>
      </div>
    </div>

    <div v-if="installmentsEnabled" class="mt-4">
      <div class="flex flex-col sm:flex-row sm:space-x-4">
        <div class="flex-1 mb-4 sm:mb-0">
          <label
            for="installmentsPaymentDay"
            class="block text-sm font-medium text-gray-700 mb-1"
          >
            Auto-payment day:
          </label>
          <Select
            v-model="installmentsPaymentDay"
            :options="paymentDays"
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            class="w-full"
          />

          <small
            v-if="
              v$ErrorMessage(fieldValidations.installmentsPaymentDay?.$errors)
            "
            class="error-message"
          >
            {{
              v$ErrorMessage(fieldValidations.installmentsPaymentDay.$errors)
            }}
          </small>
        </div>
        <div class="flex-1">
          <label
            for="installmentsTermLength"
            class="block text-sm font-medium text-gray-700 mb-1"
          >
            Term
          </label>
          <div class="flex">
            <InputNumber
              v-model="installmentsTermLength"
              class="flex-grow"
              inputClass="rounded-r-none"
              :min="2"
            />
            <InputGroupAddon
              class="bg-gray-200 border border-l-0 rounded-r px-3 flex items-center"
            >
              Months
            </InputGroupAddon>
          </div>
          <small
            v-if="
              v$ErrorMessage(fieldValidations.installmentsTermLength?.$errors)
            "
            class="error-message"
          >
            {{
              v$ErrorMessage(fieldValidations.installmentsTermLength.$errors)
            }}
          </small>
        </div>
      </div>
      <div class="mt-4">
        <label class="block text-sm font-medium text-gray-700 mb-1">
          Installment Amount
        </label>
        <div v-if="hasPaymentTerms">
          <p class="text-sm text-gray-500">{{ paymentPerTerm }}</p>
        </div>
        <div v-else>
          <p class="text-sm text-gray-500">Enter payment day and term</p>
        </div>
      </div>
    </div>
  </AppCard>
</template>
