import type {
  PaginatedSubscriptionPlanList,
  SubscriptionPlan,
  AdminV1PaymentsSubscriptionPlansListParams,
} from "@/api/model";
import { ref, type Ref } from "vue";
import { getSubscriptions } from "../api";

const PAGE_SIZE = 20;
const FIRST_PAGE = 0;

export function useSubscriptions(): {
  paginatedSubscriptions: Ref<PaginatedSubscriptionPlanList | null>;
  fetchSubscriptions: () => Promise<void>;
  subscriptionFetchError: Ref<string | null>;
  currentPage: Ref<number>;
  pageSize: Ref<number>;
  totalRecords: Ref<number | undefined>;
} {
  const pageSize = ref(PAGE_SIZE);
  const currentPage = ref(FIRST_PAGE);
  const totalRecords = ref<number | undefined>(0);

  const subscriptionFetchError = ref<string | null>(null);
  const paginatedSubscriptions = ref<PaginatedSubscriptionPlanList | null>(
    null
  );

  async function fetchSubscriptions() {
    const offset = currentPage.value * pageSize.value;
    const params: AdminV1PaymentsSubscriptionPlansListParams = {};

    params.limit = pageSize.value;
    params.offset = offset;

    const results = await getSubscriptions(params);

    if ("error" in results) {
      subscriptionFetchError.value = String(results.error);
    } else {
      paginatedSubscriptions.value = results;
      totalRecords.value = results.count;
    }
  }

  return {
    paginatedSubscriptions,
    fetchSubscriptions,
    subscriptionFetchError,
    currentPage,
    pageSize,
    totalRecords,
  };
}
